import React, { Component } from 'react';

class NotFound extends Component {
    render() {
        return (
            <div id="noMatch">Not found</div>
        )
    }
}

export default NotFound;